import * as React from 'react'
import {Router} from 'react-router-dom'
import {CompatRouter, Route, Routes, useNavigate, useParams} from 'react-router-dom-v5-compat'
import {PageContentSlot} from '@wf-mfe/layout'
import {Phoenix} from '@phoenix/all'
import {history} from '@wf-mfe/navigation'
import {useIsTreatmentEnabled} from '@wf-mfe/toggles'

import {PlanWrapperComponent} from './view/Plan/PlanWrapperComponent'
import {PlanListWrapperComponent} from './view/PlanList'
import {RoleService} from './shared/services/RoleService'

const ScenarioPlannerWrapper: React.FC = ({children}) => {
  return (
    <PageContentSlot>
      <Phoenix injectContainers={false}>
        <Router history={history}>
          <CompatRouter>{children}</CompatRouter>
        </Router>
      </Phoenix>
    </PageContentSlot>
  )
}

export const ScenarioPlanner = () => {
  const isReactRouterV6Enabled = useIsTreatmentEnabled('react-router-v6')

  return (
    <ScenarioPlannerWrapper>
      {isReactRouterV6Enabled ? (
        <PlanListWrapperComponent />
      ) : (
        <Routes>
          <Route element={<PlanListWrapperComponent />} path="/plans" />
          <Route element={<PlanWrapperWrapper />} path="/plan/:planID" />
        </Routes>
      )}
    </ScenarioPlannerWrapper>
  )
}

export const ScenarioPlan = () => {
  return (
    <ScenarioPlannerWrapper>
      <PlanWrapperWrapper />
    </ScenarioPlannerWrapper>
  )
}

const roleService = new RoleService()

const PlanWrapperWrapper = () => {
  const navigate = useNavigate()
  const {planID = ''} = useParams<{planID: string}>()
  const isNewPlan = planID === 'new'
  const navigateToList = React.useCallback(
    (planId?, scenarioId?) => {
      if (isNewPlan && planId && scenarioId) {
        navigate(`/plan/${planId}#${scenarioId}`, {replace: true})
      } else if (planId && typeof planId === 'string') {
        navigate(`/plan/${planId}`)
      } else {
        navigate(`/plans`)
      }
    },
    [isNewPlan, navigate]
  )

  const toggles = {
    FILTER_SP_PLANS_LIST: useIsTreatmentEnabled('new-filter-sp-plans-list'),
  }

  const loadRoles = () => roleService.searchRoles('')
  return (
    <PlanWrapperComponent
      toggles={toggles}
      history={history}
      planId={isNewPlan ? null : planID}
      rolesLoadFunction={loadRoles}
      navigateToListFunction={navigateToList}
      onSuccessfulSaveFunction={navigateToList}
    />
  )
}
