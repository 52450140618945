import {createReactLifecycles} from 'single-spa-workfront'
import {ScenarioPlan, ScenarioPlanner} from './planner'

const lifecycles = createReactLifecycles({
  rootComponent: ScenarioPlanner,
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount

export const Component = ScenarioPlanner
export const ScenarioPlanComponent = ScenarioPlan
