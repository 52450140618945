import * as React from 'react'
import {connect} from 'react-redux'
import {useNavigate} from 'react-router-dom-v5-compat'
import {useCurrentUser} from '@wf-mfe/auth'
import {deletePlans} from '../../data/planList'
import {loadPermissions} from '../../data'
import SplitContext, {ISplitContext} from '../../shared/context/SplitContext'
import {useEffect} from 'react'
import {translate} from '../../shared/utilities/TranslateHelper'
import PlanList from '../../../spLists/plan'
import {LoaderComponent} from '../shared'

interface IPlanListWrapperComponentProps {
  loadPermissionsFunction: (objID: string | null) => Promise<Map<string, string>>
  deletePlans: (ids: string[]) => Promise<undefined>
  redirectToLoginPage: () => void
}

const NewPlanList: React.FC<IPlanListWrapperComponentProps> = (props) => {
  const currentUser = useCurrentUser()
  const navigate = useNavigate()
  const isSplitEnabled: ISplitContext = React.useContext(SplitContext)

  useEffect(() => {
    document.title = translate('sp.scenarios')
  }, [])

  const createNewPlanFunction = React.useCallback(() => {
    navigate(`/plan/new`)
  }, [navigate])

  return (
    <React.Suspense fallback={<LoaderComponent isLoading={true} />}>
      <PlanList
        currentUserID={currentUser.ID}
        loadPermissionsFunction={props.loadPermissionsFunction}
        createNewPlanFunction={createNewPlanFunction}
        redirectToLoginPage={props.redirectToLoginPage}
        newFilterSpPlansListSplit={!!isSplitEnabled.FILTER_SP_PLANS_LIST}
      />
    </React.Suspense>
  )
}

const mapDispatchToProps = (dispatch) => ({
  loadPermissionsFunction: () => dispatch(loadPermissions(null)),
  deletePlans: (ids: string[]) => dispatch(deletePlans(ids)),
})

export const NewPlanListComponent = connect(null, mapDispatchToProps)(NewPlanList)
