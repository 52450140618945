import * as React from 'react'
import {LoadLocalizations, LocalizationProvider} from '@wf-mfe/localize-react'
import {DataFetcherOptions} from './plugins/dataFetcher'
import {importMessageKeys} from './messageKeys'
import {ProjectImportList} from './ProjectImportList'
import {espClientFactory} from '../../shared/L10n'

export interface ProjectImportListComponentProps extends Partial<DataFetcherOptions> {
  onSelectionChange?: (ids: string[]) => void
  history?: Record<string, unknown>
  planId?: string
}

export const ProjectImportListWrapperComponent: React.FC<ProjectImportListComponentProps> = (
  props
) => {
  return (
    <LocalizationProvider clientFactory={espClientFactory}>
      <LoadLocalizations messageKeys={importMessageKeys}>
        <ProjectImportList {...props} />
      </LoadLocalizations>
    </LocalizationProvider>
  )
}
